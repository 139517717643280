import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderByUserIdAsync, resetOrderFetchStatus, selectOrderFetchStatus, selectOrders, cancelOrderAsync, returnOrderAsync } from '../OrderSlice'; // Add returnOrderAsync here
import { selectLoggedInUser } from '../../auth/AuthSlice';
import { Button, IconButton, Paper, Stack, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import { loadingAnimation, noOrdersAnimation } from '../../../assets';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { motion } from 'framer-motion';

export const UserOrders = () => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(selectLoggedInUser);
    const orders = useSelector(selectOrders);
    const orderFetchStatus = useSelector(selectOrderFetchStatus);

    const theme = useTheme();
    const is1200 = useMediaQuery(theme.breakpoints.down("1200"));
    const is480 = useMediaQuery(theme.breakpoints.down("480"));

    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [openReturnDialog, setOpenReturnDialog] = useState(false); // State for return dialog
    const [cancelReason, setCancelReason] = useState('');
    const [returnReason, setReturnReason] = useState(''); // State for return reason
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        dispatch(getOrderByUserIdAsync(loggedInUser?._id));
    }, [dispatch, loggedInUser]);

    useEffect(() => {
        if (orderFetchStatus === 'rejected') {
            toast.error("Error fetching orders, please try again later");
        }
    }, [orderFetchStatus]);

    useEffect(() => {
        return () => {
            dispatch(resetOrderFetchStatus());
        };
    }, [dispatch]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'Pending': return { bgcolor: '#dfc9f7', color: '#7c59a4' };
            case 'Dispatched': return { bgcolor: '#feed80', color: '#927b1e' };
            case 'Out for delivery': return { bgcolor: '#AACCFF', color: '#4793AA' };
            case 'Delivered': return { bgcolor: "#b3f5ca", color: "#548c6a" };
            case 'Cancelled': return { bgcolor: "#fac0c0", color: '#cc6d72' };
            case 'Pending Cancellation': return { bgcolor: '#FFEBEE', color: '#C62828' };
            default: return {};
        }
    };

    const handleOpenCancelDialog = (orderId) => {
        setSelectedOrder(orderId);
        setOpenCancelDialog(true);
    };

    const handleOpenReturnDialog = (orderId) => { // Function to open return dialog
        setSelectedOrder(orderId);
        setOpenReturnDialog(true);
    };

    const handleCancelOrder = () => {
        if (!cancelReason) {
            toast.error("Please provide a reason for cancellation.");
            return;
        }

        dispatch(cancelOrderAsync({ orderId: selectedOrder, reason: cancelReason }))
            .then(() => {
                toast.success('Cancellation request sent');
                setOpenCancelDialog(false);
                setCancelReason('');
            })
            .catch(() => {
                toast.error('Error in sending cancellation request');
            });
    };

    const handleReturnOrder = () => { // Function to handle return order
        if (!returnReason) {
            toast.error("Please provide a reason for returning the product.");
            return;
        }

        dispatch(returnOrderAsync({ orderId: selectedOrder, reason: returnReason }))
            .then(() => {
                toast.success('Return request sent');
                setOpenReturnDialog(false);
                setReturnReason('');
            })
            .catch(() => {
                toast.error('Error in sending return request');
            });
    };

    return (
        <Stack justifyContent={'center'} alignItems={'center'}>
            {
                orderFetchStatus === 'pending' ?
                    <Stack width={is480 ? 'auto' : '25rem'} height={'calc(100vh - 4rem)'} justifyContent={'center'} alignItems={'center'}>
                        <Lottie animationData={loadingAnimation} />
                    </Stack>
                    :
                    <Stack width={is1200 ? "auto" : "60rem"} p={is480 ? 2 : 4} mb={'5rem'}>
                        <Stack flexDirection={'row'} columnGap={2}>
                            {!is480 && <motion.div whileHover={{ x: -5 }} style={{ alignSelf: "center" }}>
                                <IconButton component={Link} to={"/"}><ArrowBackIcon fontSize='large' /></IconButton>
                            </motion.div>}

                            <Stack rowGap={1}>
                                <Typography variant='h4' fontWeight={500}>Order history</Typography>
                                <Typography sx={{ wordWrap: "break-word" }} color={'text.secondary'}>Check the status of recent orders, manage returns, and discover similar products.</Typography>
                            </Stack>
                        </Stack>

                        <Stack mt={5} rowGap={5}>
                            {orders && orders.map((order) => (
                                <Stack key={order._id} p={is480 ? 0 : 2} component={is480 ? "" : Paper} elevation={1} rowGap={2}>
                                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                                        <Stack>
                                            <Typography>Order Number</Typography>
                                            <Typography color={'text.secondary'}>{order._id}</Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography>Date Placed</Typography>
                                            <Typography color={'text.secondary'}>{new Date(order.createdAt).toDateString()}</Typography>
                                        </Stack>
                                        <Stack>
                                            <Typography>Total Amount</Typography>
                                            <Typography>₹{order.total}</Typography>
                                        </Stack>
                                    </Stack>

                                    {/* Display product images */}
                                    {order.item.map((product) => (
                                        <Stack flexDirection={'row'} columnGap={2} mt={2} key={product._id}>
                                            <img
                                                src={product.product.images[0]}
                                                alt={product.product.title}
                                                style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                                            />
                                            <Stack>
                                                <Typography variant='h6'>{product.product.title}</Typography>
                                                <Typography color='text.secondary'>{product.product.brand.name}</Typography>
                                                <Typography color='text.secondary'>Qty: {product.quantity}</Typography>
                                            </Stack>
                                        </Stack>
                                    ))}

                                    <Stack mt={2} flexDirection={'row'} justifyContent={'space-between'}>
                                        <Typography mb={2} sx={getStatusColor(order.status)} padding={1} borderRadius={20}>Status: {order.status}</Typography>
                                        <Stack direction="row" spacing={2}>
                                            {['Pending', 'Dispatched', 'Out for delivery'].includes(order.status) && (
                                                <Button variant="outlined" color="error" onClick={() => handleOpenCancelDialog(order._id)}>
                                                    Cancel Order
                                                </Button>
                                            )}

                                            {order.status === 'Delivered' && (
                                                <Button variant="outlined" color="primary" onClick={() => handleOpenReturnDialog(order._id)}>
                                                    Return Order
                                                </Button>
                                            )}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ))}

                            {!orders.length && (
                                <Stack mt={'2rem'} mb={'7rem'} alignSelf={'center'} rowGap={2}>
                                    <Stack width={'30rem'} height={'30rem'}>
                                        <Lottie animationData={noOrdersAnimation} />
                                    </Stack>
                                    <Typography textAlign={'center'} variant='h6'>Oh! Looks like you haven't been shopping lately</Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
            }

            {/* Cancel Order Dialog */}
            <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
                <DialogTitle>Cancel Order</DialogTitle>
                <DialogContent>
                    <Typography>Why do you want to cancel this order?</Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        placeholder="Enter your reason for cancellation"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCancelDialog(false)} color="primary">Close</Button>
                    <Button onClick={handleCancelOrder} color="error">Submit</Button>
                </DialogActions>
            </Dialog>

            {/* Return Order Dialog */}
            <Dialog open={openReturnDialog} onClose={() => setOpenReturnDialog(false)}>
                <DialogTitle>Return Order</DialogTitle>
                <DialogContent>
                    <Typography>Why do you want to return this product?</Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={returnReason}
                        onChange={(e) => setReturnReason(e.target.value)}
                        placeholder="Enter your reason for returning"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenReturnDialog(false)} color="primary">Close</Button>
                    <Button onClick={handleReturnOrder} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};
