import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addProductAsync, resetProductAddStatus, selectProductAddStatus } from '../../products/ProductSlice';
import { Button, FormControl, FormGroup, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme, FormHelperText, FormControlLabel, Checkbox, Box } from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import { selectBrands } from '../../brands/BrandSlice';
import { selectCategories } from '../../categories/CategoriesSlice';
import { toast } from 'react-toastify';
import axios from 'axios';
import { axiosi } from '../../../config/axios';
import { FixedSizeList } from 'react-window';
import productData from './Productdata.json';



const colorOptions = productData.colorOptions;
const typeOptions = productData.typeOptions;
const fabricOptions = productData.fabricOptions;
const idealForOptions = productData.idealForOptions;
const patternOptions = productData.patternOptions;
const topLengthOptions = productData.topLengthOptions;
const occasionOptions = productData.occasionOptions;
const fabricCareOptions = productData.fabricCareOptions;
const bottomLengthOptions = productData.bottomLengthOptions;
const dupattaLengthOptions = productData.dupattaLengthOptions;
const stitchingTypeOptions = productData.stitchingTypeOptions;
const fabricPurityOptions = productData.fabricPurityOptions;
const ornamentationTypeOptions = productData.ornamentationTypeOptions;
const regionalSpecialityOptions = productData.regionalSpecialityOptions;
const patternPrintTypeOptions = productData.patternPrintTypeOptions;
const trendOptions = productData.trendOptions;
const secondaryColorOptions = productData.secondaryColorOptions;


const ClothingComponent = ({ register, errors }) => {
    return (
        <Stack flexDirection={'row'} flexWrap="wrap" gap={2}>
            {/* Color Selection */}
            <FormControl fullWidth>
                <InputLabel id="color-selection">Color</InputLabel>
                <Select {...register("color", { required: "Color is required" })} labelId="color-selection" label="Color">
                    {colorOptions.map((color) => (
                        <MenuItem key={color} value={color}>{color}</MenuItem>
                    ))}
                </Select>
                {errors.color && (
                    <FormHelperText error>{errors.color.message}</FormHelperText>
                )}
            </FormControl>

            {/* Type Selection */}
            <FormControl fullWidth>
                <InputLabel id="type-selection">Type</InputLabel>
                <Select {...register("type", { required: "Type is required" })} labelId="type-selection" label="Type">
                    {typeOptions.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </Select>
                {errors.type && (
                    <FormHelperText error>{errors.type.message}</FormHelperText>
                )}
            </FormControl>

            {/* Fabric Selection */}
            <FormControl fullWidth>
                <InputLabel id="fabric-selection">Fabric</InputLabel>
                <Select {...register("fabric", { required: "Fabric is required" })} labelId="fabric-selection" label="Fabric">
                    {fabricOptions.map((fabric) => (
                        <MenuItem key={fabric} value={fabric}>{fabric}</MenuItem>
                    ))}
                </Select>
                {errors.fabric && (
                    <FormHelperText error>{errors.fabric.message}</FormHelperText>
                )}
            </FormControl>

            {/* Pattern Selection */}
            <FormControl fullWidth>
                <InputLabel id="pattern-selection">Pattern</InputLabel>
                <Select {...register("pattern", { required: "Pattern is required" })} labelId="pattern-selection" label="Pattern">
                    {patternOptions.map((pattern) => (
                        <MenuItem key={pattern} value={pattern}>{pattern}</MenuItem>
                    ))}
                </Select>
                {errors.pattern && (
                    <FormHelperText error>{errors.pattern.message}</FormHelperText>
                )}
            </FormControl>

            <FormControl fullWidth>
                <InputLabel id="idealFor-selection">Ideal For</InputLabel>
                <Select {...register("idealFor", { required: "Ideal For is required" })} labelId="idealFor-selection" label="Ideal For">
                    {idealForOptions.map((idealFor) => (
                        <MenuItem key={idealFor} value={idealFor}>{idealFor}</MenuItem>
                    ))}
                </Select>
                {errors.idealFor && (
                    <FormHelperText error>{errors.idealFor.message}</FormHelperText>
                )}
            </FormControl>

            <FormControl fullWidth>
                <InputLabel id="topLength-selection">Top Length</InputLabel>
                <Select {...register("topLength", { required: "Top Length is required" })} labelId="topLength-selection" label="Top Length">
                    {topLengthOptions.map((length) => (
                        <MenuItem key={length} value={length}>{length}</MenuItem>
                    ))}
                </Select>
                {errors.topLength && (
                    <FormHelperText error>{errors.topLength.message}</FormHelperText>
                )}
            </FormControl>

            <FormControl fullWidth>
                <InputLabel id="occasion-selection">Occasion</InputLabel>
                <Select {...register("occasion", { required: "Occasion is required" })} labelId="occasion-selection" label="Occasion">
                    {occasionOptions.map((occasion) => (
                        <MenuItem key={occasion} value={occasion}>{occasion}</MenuItem>
                    ))}
                </Select>
                {errors.occasion && (
                    <FormHelperText error>{errors.occasion.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="fabricCare-selection">Fabric Care</InputLabel>
                <Select {...register("fabricCare", { required: "Fabric Care is required" })} labelId="fabricCare-selection" label="Fabric Care">
                    {fabricCareOptions.map((care) => (
                        <MenuItem key={care} value={care}>{care}</MenuItem>
                    ))}
                </Select>
                {errors.fabricCare && (
                    <FormHelperText error>{errors.fabricCare.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="bottomLength-selection">Bottom Length</InputLabel>
                <Select {...register("bottomLength", { required: "Bottom Length is required" })} labelId="bottomLength-selection" label="Bottom Length">
                    {bottomLengthOptions.map((length) => (
                        <MenuItem key={length} value={length}>{length}</MenuItem>
                    ))}
                </Select>
                {errors.bottomLength && (
                    <FormHelperText error>{errors.bottomLength.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="dupattaLength-selection">Dupatta Length</InputLabel>
                <Select {...register("dupattaLength", { required: "Dupatta Length is required" })} labelId="dupattaLength-selection" label="Dupatta Length">
                    {dupattaLengthOptions.map((length) => (
                        <MenuItem key={length} value={length}>{length}</MenuItem>
                    ))}
                </Select>
                {errors.dupattaLength && (
                    <FormHelperText error>{errors.dupattaLength.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="stitchingType-selection">Stitching Type</InputLabel>
                <Select {...register("stitchingType", { required: "Stitching Type is required" })} labelId="stitchingType-selection" label="Stitching Type">
                    {stitchingTypeOptions.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </Select>
                {errors.stitchingType && (
                    <FormHelperText error>{errors.stitchingType.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="fabricPurity-selection">Fabric Purity</InputLabel>
                <Select {...register("fabricPurity", { required: "Fabric Purity is required" })} labelId="fabricPurity-selection" label="Fabric Purity">
                    {fabricPurityOptions.map((purity) => (
                        <MenuItem key={purity} value={purity}>{purity}</MenuItem>
                    ))}
                </Select>
                {errors.fabricPurity && (
                    <FormHelperText error>{errors.fabricPurity.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="ornamentationType-selection">Ornamentation Type</InputLabel>
                <Select {...register("ornamentationType", { required: "Ornamentation Type is required" })} labelId="ornamentationType-selection" label="Ornamentation Type">
                    {ornamentationTypeOptions.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </Select>
                {errors.ornamentationType && (
                    <FormHelperText error>{errors.ornamentationType.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="regionalSpeciality-selection">Regional Speciality</InputLabel>
                <Select {...register("regionalSpeciality", { required: "Regional Speciality is required" })} labelId="regionalSpeciality-selection" label="Regional Speciality">
                    {regionalSpecialityOptions.map((speciality) => (
                        <MenuItem key={speciality} value={speciality}>{speciality}</MenuItem>
                    ))}
                </Select>
                {errors.regionalSpeciality && (
                    <FormHelperText error>{errors.regionalSpeciality.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="patternPrintType-selection">Pattern Print Type</InputLabel>
                <Select {...register("patternPrintType", { required: "Pattern Print Type is required" })} labelId="patternPrintType-selection" label="Pattern Print Type">
                    {patternPrintTypeOptions.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </Select>
                {errors.patternPrintType && (
                    <FormHelperText error>{errors.patternPrintType.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="trend-selection">Trend</InputLabel>
                <Select {...register("trend", { required: "Trend is required" })} labelId="trend-selection" label="Trend">
                    {trendOptions.map((trend) => (
                        <MenuItem key={trend} value={trend}>{trend}</MenuItem>
                    ))}
                </Select>
                {errors.trend && (
                    <FormHelperText error>{errors.trend.message}</FormHelperText>
                )}
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id="secondaryColor-selection">Secondary Color</InputLabel>
                <Select {...register("secondaryColor", { required: "Secondary Color is required" })} labelId="secondaryColor-selection" label="Secondary Color">
                    {secondaryColorOptions.map((color) => (
                        <MenuItem key={color} value={color}>{color}</MenuItem>
                    ))}
                </Select>
                {errors.secondaryColor && (
                    <FormHelperText error>{errors.secondaryColor.message}</FormHelperText>
                )}
            </FormControl>


        </Stack>
    )
}

export default ClothingComponent