import React, { useEffect, useState } from 'react';
import { Navbar } from '../features/navigation/components/Navbar';
import { resetAddressStatus, selectAddressStatus } from '../features/address/AddressSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from '../features/footer/Footer';
import { useSearchParams } from 'react-router-dom';
import { fetchProductsAsync } from '../features/products/ProductSlice';
import { AdminSearch } from '../features/admin/components/AdminSearch';

export const AdminSearchPage = () => {
    const dispatch = useDispatch();
    const addressStatus = useSelector(selectAddressStatus);
    const [searchQuery, setSearchQuery] = useState(''); // State to store search query
    const [searchParams] = useSearchParams(); // Get search params from URL

    // Handle search query from URL
    useEffect(() => {
        const query = searchParams.get('query');
        if (query && query.trim()) {
            setSearchQuery(query.trim());
        }
    }, [searchParams]);

    // Fetch products when searchQuery updates
    useEffect(() => {
        if (searchQuery) {
            dispatch(fetchProductsAsync({ search: searchQuery })); // Dispatch action to fetch products
        }
    }, [searchQuery, dispatch]); // Runs when searchQuery is updated

    // Handle address status
    useEffect(() => {
        if (addressStatus === 'fulfilled') {
            dispatch(resetAddressStatus());
        }
    }, [addressStatus, dispatch]);

    return (
        <>

            <Navbar isProductList={true} setSearchQuery={setSearchQuery} />

            <AdminSearch searchQuery={searchQuery} />

            <Footer />
        </>
    );
};
