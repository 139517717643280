import { axiosi } from "../../config/axios"

export const fetchLoggedInUserById = async (id) => {
    try {
        const res = await axiosi.get(`/users/${id}`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const updateUserById = async (update) => {
    try {
        const res = await axiosi.patch(`/users/${update._id}`, update)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}
export const updateAvatarById = async ({ userId, formData }) => {
    try {
        // console.log(userId, formData);
        const res = await axiosi.patch(`/users/avatar/${userId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return res.data
    } catch (error) {
        throw error.response.data
    }
}