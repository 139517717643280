// src/components/Notification.js
import React, { useEffect, useState } from 'react';
import { axiosi } from '../../../config/axios';

const Notification = ({ userId }) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axiosi.get(`/api/notifications/${userId}`);
                setNotifications(response.data);
            } catch (error) {
                console.error("Error fetching notifications", error);
            }
        };

        fetchNotifications();
    }, [userId]);

    const markAsRead = async (notificationId) => {
        try {
            await axiosi.patch(`/api/notifications/${notificationId}`);
            setNotifications((prev) =>
                prev.map((notification) =>
                    notification._id === notificationId ? { ...notification, read: true } : notification
                )
            );
        } catch (error) {
            console.error("Error marking notification as read", error);
        }
    };

    return (
        <div>
            <h2>Notifications</h2>
            {notifications.length === 0 ? (
                <p>No notifications</p>
            ) : (
                notifications.map((notification) => (
                    <div key={notification._id} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0' }}>
                        <p>{notification.message}</p>
                        <p>{new Date(notification.createdAt).toLocaleString()}</p>
                        {!notification.read && (
                            <button onClick={() => markAsRead(notification._id)}>Mark as read</button>
                        )}
                    </div>
                ))
            )}
        </div>
    );
};

export default Notification;
