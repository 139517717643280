import React, { useState, useEffect } from 'react';
import './Coupon.css';
import { axiosi } from '../../../config/axios';
import { Stack, Typography, TextField, Button, Paper, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete'; // Import Delete icon from MUI

export const Coupon = () => {
    const [coupons, setCoupons] = useState([]);
    const [newCoupon, setNewCoupon] = useState({
        code: '',
        discount: '',
        expiryDate: ''
    });

    useEffect(() => {
        const fetchCoupons = async () => {
            try {
                const { data } = await axiosi.get('/api/coupons');
                setCoupons(data);
            } catch (error) {
                toast.error('Error fetching coupons');
            }
        };

        fetchCoupons();
    }, []);

    const handleCreateCoupon = async () => {
        if (!newCoupon.code || !newCoupon.discount || !newCoupon.expiryDate) {
            toast.error('All fields are required');
            return;
        }

        try {
            const { data } = await axiosi.post('/api/coupons', newCoupon);
            setCoupons([...coupons, data]);
            setNewCoupon({ code: '', discount: '', expiryDate: '' });
            toast.success('Coupon created successfully');
        } catch (error) {
            toast.error('Error creating coupon');
        }
    };

    const handleDeleteCoupon = async (id) => {
        try {
            await axiosi.delete(`/api/coupons/${id}`);
            setCoupons(coupons.filter(coupon => coupon._id !== id)); // Remove coupon from list
            toast.success('Coupon deleted successfully');
        } catch (error) {
            toast.error('Error deleting coupon');
        }
    };

    return (
        <div className="coupon-container">
            {/* Create New Coupon */}
            <Typography variant="h5" mt={4} gutterBottom>
                Create New Coupon
            </Typography>
            <Stack spacing={2} className="create-coupon-form">
                <TextField
                    label="Coupon Code"
                    variant="outlined"
                    value={newCoupon.code}
                    onChange={(e) => setNewCoupon({ ...newCoupon, code: e.target.value })}
                />
                <TextField
                    label="Discount (%)"
                    variant="outlined"
                    type="number"
                    value={newCoupon.discount}
                    onChange={(e) => setNewCoupon({ ...newCoupon, discount: e.target.value })}
                />
                <TextField
                    label="Expiry Date"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={newCoupon.expiryDate}
                    onChange={(e) => setNewCoupon({ ...newCoupon, expiryDate: e.target.value })}
                />
                <Button variant="contained" color="primary" onClick={handleCreateCoupon}>
                    Create Coupon
                </Button>
            </Stack>
            <Typography variant="h4" gutterBottom>
                Coupons
            </Typography>

            {/* Display Existing Coupons */}
            <Stack spacing={2}>
                {coupons.map((coupon) => (
                    <Paper key={coupon._id} className="coupon-card" elevation={2}>
                        <Typography variant="h6">Code: {coupon.code}</Typography>
                        <Typography>Discount: {coupon.discount}%</Typography>
                        <Typography>Expiry: {new Date(coupon.expiryDate).toLocaleDateString()}</Typography>
                        <IconButton onClick={() => handleDeleteCoupon(coupon._id)} color="secondary">
                            <DeleteIcon />
                        </IconButton>
                    </Paper>
                ))}
            </Stack>

        </div>
    );
};
