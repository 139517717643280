import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { selectCategories } from '../categories/CategoriesSlice';
import { useSelector } from 'react-redux';

const CategoryCarousel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  let catdata = useSelector(selectCategories);

  const staticBasePath = "/images"; // Static base path for images

  const categories = [
    { id: 1, name: "clothing", image: `${staticBasePath}/sarees.jpg` },
    { id: 2, name: "jewellery", image: `${staticBasePath}/jewelry.jpg` },
    { id: 3, name: "hardware", image: `${staticBasePath}/curtain-rods.jpg` },
  ];

  useEffect(() => {
    if (catdata) {
      const updatedCatData = catdata.map((cat) => {
        const matchedCategory = categories.find((category) => category.name === cat.name);
        return matchedCategory ? { ...cat, image: matchedCategory.image } : cat;
      });
    }
  }, [catdata, categories]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 4,  // Dynamic slides based on screen size
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const navigate = useNavigate();

  const handleCategoryClick = (e, categoryId) => {
    e.preventDefault();
    let searchQuery = categoryId
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
    }
  };

  return (
    <Box sx={{ width: "95%" }}>
      <Typography variant="h5" align="center" gutterBottom>
        Shop by Category
      </Typography>
      <Slider {...settings}>
        {categories.map((category) => (
          <Box key={category.id} onClick={(e) => handleCategoryClick(e, category.name)} sx={{ p: 2 }}>
            <img
              src={category.image}
              alt={category.name}
              style={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
            <Typography variant="h6" align="center">
              {category.name}
            </Typography>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default CategoryCarousel;
