import React, { useEffect, useState } from 'react';
import { Navbar } from '../features/navigation/components/Navbar';
import { ProductSearch } from '../features/products/components/ProductSearch';
import { resetAddressStatus, selectAddressStatus } from '../features/address/AddressSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from '../features/footer/Footer';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import { fetchProductsAsync } from '../features/products/ProductSlice';

export const SearchPage = () => {
  const dispatch = useDispatch();
  const addressStatus = useSelector(selectAddressStatus);
  const [searchQuery, setSearchQuery] = useState(''); // State to store search query
  const [searchParams] = useSearchParams(); // Get search params from URL

  // Handle search query from URL
  useEffect(() => {
    const query = searchParams.get('query');
    if (query && query.trim()) {
      setSearchQuery(query.trim());
    }
  }, [searchParams]);

  // Fetch products when searchQuery updates
  useEffect(() => {
    if (searchQuery) {
      dispatch(fetchProductsAsync({ search: searchQuery })); // Dispatch action to fetch products
    }
  }, [searchQuery, dispatch]); // Runs when searchQuery is updated

  // Handle address status
  useEffect(() => {
    if (addressStatus === 'fulfilled') {
      dispatch(resetAddressStatus());
    }
  }, [addressStatus, dispatch]);

  return (
    <>
      <Helmet>
        <title> Products | My Awesome Site</title>
        <meta name="description" content="This is the home page of my awesome website." />
        <meta name="keywords" content="home, awesome site, react helmet" />
        <meta property="og:title" content="Home Page | My Awesome Site" />
        <meta property="og:description" content="Visit the home page of this fantastic site." />
        <meta property="og:url" content="https://www.myawesomesite.com" />
      </Helmet>

      <Navbar isProductList={true} setSearchQuery={setSearchQuery} />

      {/* Pass the search query to ProductSearch */}
      <ProductSearch searchQuery={searchQuery} />

      <Footer />
    </>
  );
};
