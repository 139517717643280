import React from 'react';
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Navbar } from '../features/navigation/components/Navbar';
import { Footer } from '../features/footer/Footer';

function FAQ() {
    return (
        <>
            <Navbar />
            <Container>
                <Box sx={{ my: 4 }}>
                    <Typography variant="h3" gutterBottom align="center">
                        Frequently Asked Questions
                    </Typography>

                    {/* Question 1 */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6">What is Dhrudeep Enterprise?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Dhrudeep Enterprise is a business specializing in quality products, ranging from textiles to
                                household items. We focus on delivering premium products to our customers.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 2 */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="h6">What payment methods do you accept?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We accept all major credit and debit cards, as well as UPI payments and bank transfers.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 3 */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography variant="h6">How do I track my order?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Once your order is shipped, you will receive a tracking number via email. You can use that
                                number on our website to track your order.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Updated Question 4 */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography variant="h6">What is your return policy?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We offer a 7-day return policy. To initiate a return, please contact us via WhatsApp with your order details, and we will assist you with the process.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Add more FAQ questions as needed */}
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default FAQ;
