import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { Stack } from '@mui/material'
import React from 'react'
import { QRCodePng, appStorePng, googlePlayPng, facebookPng, instagramPng, twitterPng, linkedinPng } from '../../assets'
import SendIcon from '@mui/icons-material/Send';
import { MotionConfig, motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const Footer = () => {

    const theme = useTheme()
    const is700 = useMediaQuery(theme.breakpoints.down(700))

    const labelStyles = {
        fontWeight: 300,
        cursor: 'pointer'
    }

    return (
        <Stack sx={{ backgroundColor: theme.palette.primary.main, paddingTop: "3rem", paddingLeft: is700 ? "1rem" : "3rem", paddingRight: is700 ? "1rem" : "3rem", paddingBottom: "1.5rem", rowGap: "5rem", color: theme.palette.primary.light, justifyContent: "space-around" }}>

            {/* upper */}
            <Stack flexDirection={'row'} rowGap={'1rem'} justifyContent={is700 ? "" : 'space-around'} flexWrap={'wrap'}>

                <Stack rowGap={'1rem'} padding={'1rem'}>
                    <Typography variant='h6'>Support</Typography>
                    <Typography sx={labelStyles}>
                        F-1 101, Patel chembar, near bapashitaram petrol pump, umragam, velanja, Surat, Gujarat, India, 394130
                    </Typography>
                    <Typography sx={labelStyles}>
                        <a href="mailto:dhrudeepenterprise07@gmail.com" style={{ color: 'inherit', textDecoration: 'none' }}>dhrudeepenterprise07@gmail.com</a>
                    </Typography>
                    <Typography sx={labelStyles}>
                        <a href="tel:9510938002" style={{ color: 'inherit', textDecoration: 'none' }}>9510938002</a>
                    </Typography>
                    {/* <Typography sx={labelStyles}>
                        <a href="tel:72659018002" style={{ color: 'inherit', textDecoration: 'none' }}>72659018002</a>
                    </Typography> */}
                </Stack>

                <Stack rowGap={'1rem'} padding={'1rem'}>
                    <Typography variant='h6'>Account</Typography>
                    <Link to="/profile" style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Typography sx={labelStyles}>My Account</Typography>
                    </Link>
                    <Link to="/login" style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Typography sx={labelStyles}>Login / Register</Typography>
                    </Link>
                    <Link to="/cart" style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Typography sx={labelStyles}>Cart</Typography>
                    </Link>
                    <Link to="/wishlist" style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Typography sx={labelStyles}>Wishlist</Typography>
                    </Link>
                    <Link to="/shop" style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Typography sx={labelStyles}>Shop</Typography>
                    </Link>
                </Stack>

                <Stack rowGap={'1rem'} padding={'1rem'}>
                    <Typography variant='h6'>Quick Links</Typography>
                    <Link to="/privacy-policy" style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Typography sx={labelStyles}>Privacy Policy</Typography>
                    </Link>
                    <Link to="/terms-of-use" style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Typography sx={labelStyles}>Terms Of Use</Typography>
                    </Link>
                    <Link to="/faq" style={{ color: 'inherit', textDecoration: 'none' }}>
                        <Typography sx={labelStyles}>FAQ</Typography>
                    </Link>
                    <a href="https://api.whatsapp.com/send/?phone=%2B919928304110&text=Hello%21+I%27m+interested+in+your+Products.&type=phone_number&app_absent=0" target='_blank' style={{ color: 'inherit', textDecoration: 'none' }}>Contact</a>
                </Stack>

                {/* Social Media Section */}
                <Stack rowGap={'1rem'} padding={'1rem'}>
                    <Typography variant='h6'>Follow Us</Typography>
                    <Stack mt={.6} flexDirection={'row'} columnGap={'2rem'}>
                        <MotionConfig whileHover={{ scale: 1.1 }} whileTap={{ scale: 1 }}>
                            <motion.a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <motion.img style={{ cursor: "pointer" }} src={facebookPng} alt="Facebook" />
                            </motion.a>
                            <motion.a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <motion.img style={{ cursor: "pointer" }} src={twitterPng} alt="Twitter" />
                            </motion.a>
                            <motion.a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                <motion.img style={{ cursor: "pointer" }} src={instagramPng} alt="Instagram" />
                            </motion.a>
                            <motion.a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                <motion.img style={{ cursor: "pointer" }} src={linkedinPng} alt="LinkedIn" />
                            </motion.a>
                        </MotionConfig>
                    </Stack>
                </Stack>
            </Stack>

            {/* lower */}
            <Stack alignSelf={"center"}>
                <Typography color={'GrayText'}>&copy; DhrudeepEnterprise {new Date().getFullYear()}. All rights reserved</Typography>
            </Stack>

        </Stack>
    )
}
