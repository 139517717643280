import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@mui/material/MobileStepper';
import { Box, useTheme } from '@mui/material';
import { useState } from 'react';
import Button from '@mui/material/Button';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const ProductBanner = ({ images }) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <>
            <AutoPlaySwipeableViews
                style={{ overflow: "hidden", width: '100%', height: '100%' }}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((image, index) => (
                    <div key={index} style={{ width: "100%", height: '100%' ,overflow:"hidden" }}>
                        {Math.abs(activeStep - index) <= 2 && (
                            <Box
                                component="img"
                                sx={{
                                    width: '100%',
                                    height: '100%', // Ensure the image covers the container
                                    objectFit: "contain"
                                }}
                                src={image}
                                alt={`Banner Image ${index + 1}`} // Provide a unique alt text
                            />
                        )}
                    </div>
                ))}
            </AutoPlaySwipeableViews>

            <div style={{ alignSelf: 'center' }}>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                           { ">"}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {"<"}
                        </Button>
                    }
                />
            </div>
        </>
    );
};
