import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllOrdersAsync,
  resetOrderUpdateStatus,
  selectOrderUpdateStatus,
  selectOrders,
  updateOrderByIdAsync
} from '../../order/OrderSlice';
import {
  Avatar, Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField,
  Typography, useMediaQuery, useTheme, Box, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { noOrdersAnimation } from '../../../assets/index';
import Lottie from 'lottie-react';

export const AdminOrders = () => {
  const dispatch = useDispatch();
  const orders = useSelector(selectOrders);
  const [editIndex, setEditIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState(''); // For search
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filterStatus, setFilterStatus] = useState(''); // To store the selected status
  const orderUpdateStatus = useSelector(selectOrderUpdateStatus);
  const theme = useTheme();
  const is1620 = useMediaQuery(theme.breakpoints.down(1620));
  const is1200 = useMediaQuery(theme.breakpoints.down(1200));
  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    dispatch(getAllOrdersAsync());
  }, [dispatch]);

  useEffect(() => {
    if (orderUpdateStatus === 'fulfilled') {
      toast.success("Status updated");
    } else if (orderUpdateStatus === 'rejected') {
      toast.error("Error updating order status");
    }
  }, [orderUpdateStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetOrderUpdateStatus());
    };
  }, []);

  // Update filtered orders based on search or filterStatus
  useEffect(() => {
    const searchFilteredOrders = orders.filter(order => {
      return (
        order._id.includes(searchQuery) ||
        order.item.some(product =>
          product.product.SKUID?.includes(searchQuery) ||
          product.product.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.size?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          String(product.quantity).includes(searchQuery)
        ) ||
        order.user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.address.some(addr =>
          addr.street?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          addr.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          addr.state?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          addr.postalCode?.includes(searchQuery)
        ) ||
        order.paymentMode?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.razorpay_payment_id?.includes(searchQuery) ||
        order.razorpay_order_id?.includes(searchQuery) ||
        order.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        new Date(order.createdAt)?.toDateString().includes(searchQuery.toLowerCase())
      );
    });

    if (filterStatus) {
      setFilteredOrders(searchFilteredOrders.filter(order => order.status === filterStatus));
    } else {
      setFilteredOrders(searchFilteredOrders);
    }
  }, [orders, searchQuery, filterStatus]);

  const handleUpdateOrder = (data) => {
    const update = { ...data, _id: orders[editIndex]._id };
    setEditIndex(-1);
    dispatch(updateOrderByIdAsync(update));
  };

  const statusColors = {
    'Pending': { bgcolor: '#E9D8FD', color: '#6B46C1' },
    'Dispatched': { bgcolor: '#FFF9C4', color: '#8D6E63' },
    'Out for delivery': { bgcolor: '#D0EFFF', color: '#1976D2' },
    'Delivered': { bgcolor: '#C8E6C9', color: '#388E3C' },
    'Cancelled': { bgcolor: '#FFCDD2', color: '#D32F2F' },
    'Pending Cancellation': { bgcolor: '#FFEBEE', color: '#C62828' },
    'Pending Return': { bgcolor: '#FFE0B2', color: '#FF6F00' },
    'Return Approved': { bgcolor: '#B3E5FC', color: '#0288D1' },
    'Returned': { bgcolor: '#E0F2F1', color: '#00796B' }
  };

  const statusOptions = [
    'Pending', 'Dispatched', 'Out for delivery', 'Delivered',
    'Cancelled', 'Pending Cancellation', 'Pending Return',
    'Return Approved', 'Returned'
  ];

  const orderCountsByStatus = statusOptions.reduce((acc, status) => {
    acc[status] = orders.filter(order => order.status === status).length;
    return acc;
  }, {});

  return (
    <Stack justifyContent={'center'} alignItems={'center'}>
      <TextField
        label="Search Orders"
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ marginBottom: 2, marginTop: 2 }}
      />

      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', marginBottom: 2 }}>
        {statusOptions.map((status) => (
          <Button
            key={status}
            variant={filterStatus === status ? 'contained' : 'outlined'}
            onClick={() => setFilterStatus(status)}
            sx={{ bgcolor: statusColors[status].bgcolor, color: statusColors[status].color }}
          >
            {status} ({orderCountsByStatus[status]})
          </Button>
        ))}
        <Button
          variant={filterStatus === '' ? 'contained' : 'outlined'}
          onClick={() => setFilterStatus('')}
          sx={{ bgcolor: '#E0E0E0', color: '#000' }}
        >
          All ({orders.length})
        </Button>
      </Box>

      <Stack mt={5} mb={3} component={'form'} noValidate onSubmit={handleSubmit(handleUpdateOrder)}>
        {filteredOrders.length ? (
          <TableContainer sx={{ width: is1620 ? "95vw" : "auto", overflowX: 'auto' }} component={Paper} elevation={2}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Order</TableCell>
                  <TableCell align="left">Id</TableCell>
                  <TableCell align="left">Item</TableCell>
                  <TableCell align="right">Total Amount</TableCell>
                  <TableCell align="right">Customer Name</TableCell>
                  <TableCell align="right">Customer Email</TableCell>
                  <TableCell align="right">Shipping Address</TableCell>
                  <TableCell align="right">Payment Method</TableCell>
                  <TableCell align="right">Order Date</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Return Request</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredOrders.map((order, index) => (
                  <TableRow key={order._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">{index}</TableCell>
                    <TableCell align="right">{order._id}</TableCell>
                    <TableCell align="right">
                      {order.item.map((product) => (
                        <Stack key={product.product.SKUID} mt={2} flexDirection={'row'} alignItems={'center'} columnGap={2}>
                          <Avatar src={product.product.thumbnail}></Avatar>
                          <Typography>{product.product.title}</Typography>
                          <Typography>Size: {product.size}</Typography>
                          <Typography>Qty: {product.quantity}</Typography>
                        </Stack>
                      ))}
                    </TableCell>
                    <TableCell align="right">{order.total}</TableCell>
                    <TableCell align="right">{order.user.name}</TableCell>
                    <TableCell align="right">{order.user.email}</TableCell>
                    <TableCell align="right">
                      {order.address.map((addr, idx) => (
                        <Stack key={idx}>
                          <Typography>{addr.street}</Typography>
                          <Typography>{addr.city}</Typography>
                          <Typography>{addr.state}</Typography>
                          <Typography>{addr.postalCode}</Typography>
                        </Stack>
                      ))}
                    </TableCell>
                    <TableCell align="right">{order.paymentMode}</TableCell>
                    <TableCell align="right">{new Date(order.createdAt).toDateString()}</TableCell>

                    <TableCell align="right">
                      {editIndex === index ? (
                        <FormControl fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            {...register("status", { required: true })}
                            label="Status"
                            defaultValue={order.status}
                            sx={{ minWidth: '120px' }}
                          >
                            {statusOptions.map((status) => (
                              <MenuItem key={status} value={status}>{status}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Chip
                          label={order.status}
                          sx={{ bgcolor: statusColors[order.status]?.bgcolor, color: statusColors[order.status]?.color }}
                        />
                      )}
                    </TableCell>

                    <TableCell align="right">
                      <Typography>{order.returnRequest?.status || 'N/A'}</Typography>
                      {order.returnRequest?.reason && <Typography>Reason: {order.returnRequest?.reason}</Typography>}
                      {order.cancellationReason && <Typography>Cancelled Reason: {order.cancellationReason}</Typography>}
                    </TableCell>

                    <TableCell align="right">
                      {editIndex === index ? (
                        <Stack>
                          <IconButton color='success' type='submit'>
                            <CheckCircleOutlinedIcon />
                          </IconButton>
                          <Button onClick={() => setEditIndex(-1)}>Cancel</Button>
                        </Stack>
                      ) : (
                        <IconButton color='primary' onClick={() => setEditIndex(index)}>
                          <EditOutlinedIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Stack alignItems={'center'} justifyContent={'center'}>
            <Lottie animationData={noOrdersAnimation} loop={true} />
            <Typography>No Orders Available</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
