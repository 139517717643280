import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { Navbar } from '../features/navigation/components/Navbar';
import { Footer } from '../features/footer/Footer';

function TermsOfUse() {
    return (

        <>
            <Navbar />
            <Container>
                <Box sx={{ my: 4 }}>
                    <Typography variant="h3" gutterBottom align="center">
                        Terms of Use
                    </Typography>
                    <Paper elevation={3} sx={{ p: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            1. Acceptance of Terms
                        </Typography>
                        <Typography variant="body1" paragraph>
                            By accessing and using our website, you agree to comply with and be bound by these Terms of Use. If you do not
                            agree with any part of these terms, you must not use our website.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            2. Changes to Terms
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We reserve the right to modify or replace these terms at any time. It is your responsibility to review this page
                            periodically for changes. Your continued use of the site following the posting of changes constitutes acceptance of those changes.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            3. Use of Website
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You agree not to use the website for any unlawful purpose or in any way that could damage, disable, overburden, or impair the website.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            4. Intellectual Property
                        </Typography>
                        <Typography variant="body1" paragraph>
                            All content, trademarks, and data on this website, including but not limited to software, databases, text, graphics, icons, and hyperlinks, are the property of or licensed to us and are protected by law.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            5. Limitation of Liability
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We are not liable for any damages or losses resulting from your use of the website or reliance on any information provided.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            6. Governing Law
                        </Typography>
                        <Typography variant="body1" paragraph>
                            These Terms of Use are governed by and construed in accordance with the laws of the jurisdiction in which we operate.
                        </Typography>
                    </Paper>
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default TermsOfUse;
