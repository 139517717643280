import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TextField, Button, Grid, InputLabel, MenuItem, Select, FormControl, Typography, Stack } from '@mui/material';

const CreateVariation = () => {
  const { productId } = useParams();
  const [formData, setFormData] = useState({
    SKU: "",
    color: "",
    size: "",
    price: "",
    stockQuantity: "",
    weight: "",
    images: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, images: Array.from(e.target.files) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    formDataToSend.append("SKU", formData.SKU);
    formDataToSend.append("color", formData.color);
    formDataToSend.append("size", formData.size);
    formDataToSend.append("price", formData.price);
    formDataToSend.append("stockQuantity", formData.stockQuantity);
    formDataToSend.append("weight", formData.weight);

    formData.images.forEach((file) => {
      formDataToSend.append("images", file);
    });

    try {
      const response = await axios.post(
        `/api/product/${productId}/variation`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Variation Created:", response.data);
    } catch (error) {
      console.error("Error creating variation:", error);
    }
  };

  return (
    <Stack spacing={4} mt={4} component="form" onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Create Product Variation
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="SKU"
            name="SKU"
            value={formData.SKU}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Color</InputLabel>
            <Select
              label="Color"
              name="color"
              value={formData.color}
              onChange={handleInputChange}
            >
              <MenuItem value="red">Red</MenuItem>
              <MenuItem value="blue">Blue</MenuItem>
              <MenuItem value="green">Green</MenuItem>
              {/* Add more color options as needed */}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Size"
            name="size"
            value={formData.size}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Price"
            type="number"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Stock Quantity"
            type="number"
            name="stockQuantity"
            value={formData.stockQuantity}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Weight"
            type="number"
            name="weight"
            value={formData.weight}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" component="label">
            Upload Images
            <input type="file" multiple hidden onChange={handleFileChange} />
          </Button>
        </Grid>
      </Grid>

      <Button type="submit" variant="contained" color="primary">
        Create Variation
      </Button>
    </Stack>
  );
};

export default CreateVariation;
