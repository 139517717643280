import React from 'react'
import { Coupon } from '../features/admin/Coupon/Coupon'
import { Footer } from '../features/footer/Footer'
import { Navbar } from '../features/navigation/components/Navbar'

export const CouponPage = () => {
    return (
        <>
            <Navbar />
            <Coupon />
            <Footer />
        </>
    )
}
